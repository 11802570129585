import "./styles.css";

import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react";

import ChatbotMain from './components/ChatbotMain';

// Chatbot config & functions
import API from "./ChatbotAPI";
import activeChatbotOptions from "./config";
import { getUtpCookie, isLoggedIn } from "./util";

function App() {
  // eslint-disable-next-line no-unused-vars
  const [_, _forceUpdate] = useReducer(x => x + 1, 0);
  const forceUpdate = useCallback(() => {
    console.info("Forcing update...");
    _forceUpdate();
  }, []);

  const [messages, setMessages] = useState([]);
  const [chatbotServices, setChatbotServices] = useState([]);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [phase, setPhase] = useState("intro"); // not-logged-in, wp-admin-form, intro, services, chat
  const [wpAdminData, setWpAdminData] = useState(undefined);
  const [infoBannerText, setInfoBannerText] = useState();

  const userData = useMemo(() => {
    const utpCookie = getUtpCookie();
    if (utpCookie) {
      return {
        email: utpCookie.email,
        firstname: utpCookie.given_name,
        lastname: utpCookie.family_name,
      };
    }
    return activeChatbotOptions?.userData;
  }, []);

  const showInfoBanner = useCallback((text) => {
    setInfoBannerText(text);
    setTimeout(() => {
      setInfoBannerText(undefined);
    }, 10000);
  }, [setInfoBannerText]);

  const closeInfoBanner = useCallback(() => {
    setInfoBannerText(undefined);
  }, [setInfoBannerText]);

  const endChat = useCallback(() => {
    API.GenerateTranscript({
      userEmail: userData?.email,
      username: userData?.firstname,
      llmModel: "gpt-4o",
      maxWords: 100,
    }).then((transcript) => {
      console.info(transcript);
      if (transcript?.result) {
        showInfoBanner(transcript.result);
      }
    });
    setMessages([]);
    // setPhase("intro");
    setWpAdminData(undefined);
  }, [showInfoBanner, userData?.email, userData?.firstname]);

  const requestHuman = useCallback(() => {
    API.EscalateToHumanSupport({
      userEmail: userData?.email,
    }).then((response) => {
      console.info(response);
      if (response?.result) {
        showInfoBanner(response.result);
      }
    });
    setMessages([]);
    // setPhase("intro");
    setWpAdminData(undefined);
  }, [showInfoBanner, userData?.email]);

  useEffect(() => {
    if (messages.length > 0) {
      setPhase("chat");
    } else if (!isLoggedIn() && !activeChatbotOptions.anonymousWPAdminUse) {
      setPhase("not-logged-in");
    } else if (activeChatbotOptions.anonymousWPAdminUse && wpAdminData === undefined) {
      setPhase("wp-admin-form");
    } else {
      setPhase("intro");
    }
  }, [messages.length, wpAdminData]);


  const inactivityTimers = useRef({ timeout: null });

  const resetInactivityTimers = useCallback((final) => {
    console.info("Chatbot: Resetting inactivity timers");
    clearTimeout(inactivityTimers.current.timeout);

    if (final) {
      inactivityTimers.current.timeout = setTimeout(() => {
        setMessages((prevMessages) => {
          if (prevMessages < 1) {
            return prevMessages;
          } else {
            setTimeout(endChat, 3000);
            return [
              ...prevMessages,
              {
                key: prevMessages.length,
                type: "bot",
                text: "You've been inactive for 10 minutes. Ending the session.",
              },
            ]
          }
        })
      }, 5 * 60 * 1000); // 5 minutes
    } else {
      inactivityTimers.current.timeout = setTimeout(() => {
        setMessages((prevMessages) => {
          if (prevMessages < 1) {
            return prevMessages;
          } else {
            resetInactivityTimers(true);
            return [
              ...prevMessages,
              {
                key: prevMessages.length,
                type: "bot",
                text: "You've been inactive for 5 minutes. The chat will end in 5 minutes if there is no activity.",
              }
            ]
          }

        })
      }, 5 * 60 * 1000); // 5 minutes
    }
  }, [endChat]);

  useEffect(() => {
    // Setup chatbot services
    setIsServicesLoading(true);
    API.GetChatbotServices().then((chatbotServices) => {
      // Temporary fix for Create Account service
      setChatbotServices(chatbotServices.filter((s) => !s.user_message.toLowerCase().includes("create")));
      setIsServicesLoading(false);
    });

    if (isLoggedIn() || activeChatbotOptions.anonymousWPAdminUse) {
      // Setup interactions
      API.GetInteractions().then((interactions) => {
        if (interactions.length > 0) {
          setPhase("chat");
        }
        setMessages(interactions);
      });
    }

    // This should be here for the first time the chatbot is loaded
    // the timers won't have an efffect if there are no messages
    resetInactivityTimers(false);
  }, [resetInactivityTimers]);

  const sendMessage = useCallback((text) => {
    resetInactivityTimers(false);
    try {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          key: prevMessages.length,
          type: "user",
          text: text,
        },
        {
          key: prevMessages.length + 1,
          type: "bot",
          fetchMessage: () => API.GetChatbotResponse(text),
        }
      ]);
    } catch (error) {
      console.error("Error in send:", error);
    }
  }, [resetInactivityTimers]);

  const handleServiceSelection = useCallback(async (userMessage) => {
    // Fire & forget the login event
    const utpCookie = getUtpCookie();
    await API.RecordLoginUser({
      email: utpCookie?.email,
      firstname: utpCookie?.given_name,
      lastname: utpCookie?.family_name,
    });
    setPhase("chat");
    sendMessage(userMessage);
  }, [sendMessage]);

  const handleWpAdminLogin = useCallback(async ({
    email,
    firstname,
    lastname,
  }) => {
    const response = await API.RecordUserLoginInfoByWpAdmin({
      email,
      firstname,
      lastname,
      wp_admin_email: activeChatbotOptions?.wpUserEmail,
    });
    setWpAdminData(response);
  }, []);

  return (
    <ChatbotMain
      chatbotServices={chatbotServices}
      isServicesLoading={isServicesLoading}
      messages={messages}
      onEndChat={endChat}
      onSendMessage={sendMessage}
      onServiceSelection={handleServiceSelection}
      phase={phase}
      setPhase={setPhase}
      onWpAdminLogin={handleWpAdminLogin}
      infoBannerText={infoBannerText}
      onRequestHuman={requestHuman}
      onForceUpdate={forceUpdate}
      onCloseInfoBanner={closeInfoBanner}
      userData={userData}
    />
  );
}

export default App;
