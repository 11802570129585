import activeChatbotOptions from "./config";

const UTP_COOKIE_NAME = '__utp';

export function isLoggedIn() {
  return document.cookie.includes(UTP_COOKIE_NAME) || activeChatbotOptions?.isLoggedIn;
}

export function parseCookie(name) {
  let res;
  try {
    res = JSON.parse(atob(document.cookie.split(';').find(s => s.includes(name)).split('=')[1].split('.')[1]))
  } catch (e) {
    // Cookie is not set
    console.warn('__utp cookie is not set');
  }
  return res;
}

/**
 * Get the value of the __utp cookie
 * @returns {{
 *   given_name: string,
 *   family_name: string,
 *   email: string
 * }} The partial value of the __utp cookie
 */
export function getUtpCookie() {
  return parseCookie(UTP_COOKIE_NAME);
}
