import React from "react";
import activeChatbotOptions from "../config";

export default function ChatbotMainTemplate({
  header,
  content,
  footer,
  onForceUpdate,
}) {

  const chatbotStyle = {
    backgroundColor: activeChatbotOptions.bgcolor,
    color: activeChatbotOptions.textColor,
    width: activeChatbotOptions.chatbotWidth,
  };

  return (
    <article
      className="max-h-screen max-w-screen min-h-96 flex flex-col w-full h-full bg-secondary-green rounded-lg overflow-hidden"
      style={chatbotStyle}
    >
      {!!header && (
        <section className="w-full flex items-center justify-center h-fit max-h-10 p-2 shadow-sm">
          {header}
        </section>
      )}
      {!!content && (
        <section className="w-full sm:max-w-full h-full flex flex-col items-center grow overflow-y-auto sm:px-4 sm:pt-4 px-2 pt-2">
          {content}
        </section>
      )}
      <section className="w-full h-fit flex flex-col items-center justify-end px-4 bg-transparent">
        {footer}
        <div onClick={onForceUpdate} className="p-2 uppercase text-[10px]">
          Powered by CoEditor
        </div>
      </section>

    </article>
  );
}
