/* global chatbotOptions */

// config.js
const defaultChatbotOptions = {
  isPopUp: false, // set to true to test pop up version
  bgcolor: "#E5F0EA",
  textColor: "#000000",
  // chatbotWidth: "800px",
  sandbox: true,
  notLoggedInHeader: "Log in",
  notLoggedInText: "Please log in to start chatting.",

  introButton1Url: "",
  introButton2Url: "",

  anonymousWPAdminUse: '',
};

const devOptions = {
  userData: {
    email: "ios.test@unherd.com",
    firstname: "CoEditor",
    lastname: "Developer",
  },
  wpUserEmail: 'johan.van.eck@coeditor.com',
  isLoggedIn: true,
};

const activeChatbotOptions =
  process.env.NODE_ENV === 'development'
    ? { ...defaultChatbotOptions, ...devOptions, ...window.chatbotOptions }
    : { ...defaultChatbotOptions, ...window.chatbotOptions };


export default activeChatbotOptions;
