export default function InfoBanner({ text, onClick }) {
  return (
    <div
      title={text}
      className="text-sm italic p-2 bg-primary-green rounded-lg max-w-lg truncate cursor-pointer hover:bg-opacity-50"
      onClick={onClick}
    >
      {text}
    </div>
  )
}
